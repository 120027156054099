<template>
  <a-modal
    :visible="visible"
    title="wois查询"
    :width="700"
    @cancel="handleCancel"
    @ok="submit"
    :confirmLoading="confirmLoading"
  >
    <a-form-model
      :model="form"
      ref="ruleForm"
      layout="horizontal"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="域名" ref="domain" prop="domain">
        <a-input v-model="form.domain" />
      </a-form-model-item>
    </a-form-model>
    <pre style="margin-left: 50px">{{
      whois_info == "" ? "" : JSON.stringify(whois_info, null, 4)
    }}</pre>
  </a-modal>
</template>

<script>
import { getWhoIsInfo } from "@/api/information";
export default {
  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: {
        domain: "",
      },
      rules: {
        domain: [{ required: true, message: "域名不能为空", trigger: "blur" }],
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      whois_info: "",
    };
  },
  methods: {
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          getWhoIsInfo(this.form)
            .then((res) => {
              if (res.data.status === "success") {
                this.$message.success("查询成功!");
                this.whois_info = res.data.msg;
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch((err) => {
              this.$message.error(err.toString());
            });
          this.confirmLoading = false;
        }
      });
    },
    handleCancel() {
      this.visible = false;
      this.$refs["ruleForm"].resetFields();
      this.whois_info = "";
    },
  },
};
</script>
