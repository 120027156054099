<template>
  <a-modal
    :visible="visible"
    title="域名处理"
    :width="700"
    @cancel="handleCancel"
    @ok="submit"
    :confirmLoading="confirmLoading"
  >
    <a-form-model
      :model="form"
      ref="ruleForm"
      layout="horizontal"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="域名" ref="name" prop="name">
        <a-input v-model="form.name" :disabled="this.id === 0 ? false : true" />
      </a-form-model-item>
      <a-form-model-item label="SRC" ref="src" prop="src">
        <a-select v-model="form.src" style="width: 200px">
          <a-select-option
            v-for="src_info in srcs"
            :key="src_info.id"
            :value="src_info.id"
            >{{ src_info.name }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {
  getSrcList,
  addTopDomain,
  getSingleTopDomain,
  changeTopDomain,
} from "@/api/information";
export default {
  data() {
    return {
      id: 0,
      visible: false,
      confirmLoading: false,
      form: {
        name: "",
        src: null,
      },
      rules: {
        name: [{ required: true, message: "域名不能为空", trigger: "blur" }],
      },
      srcs: [],
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
    };
  },
  methods: {
    submit() {
      if (this.id === 0) {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            this.confirmLoading = true;
            addTopDomain(this.form)
              .then((res) => {
                if (res.data.status == false) {
                  this.$message.error(res.data.message);
                } else {
                  this.$message.success(res.data.message);
                  this.$refs["ruleForm"].resetFields();
                  this.visible = false;
                  this.$parent.$parent.fetch();
                }
              })
              .catch((err) => {
                this.$message.error(err.toString());
              });
            this.confirmLoading = false;
          }
        });
      } else {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            this.confirmLoading = true;
            changeTopDomain(this.id, this.form)
              .then((res) => {
                if (res.status === 200) {
                  this.$message.success("更改成功!");
                  this.$refs["ruleForm"].resetFields();
                  this.visible = false;
                  this.$parent.$parent.fetch();
                } else {
                  this.$message.error("更改失败!");
                }
              })
              .catch((err) => {
                this.$message.error(err.toString());
              });
            this.confirmLoading = false;
          }
        });
      }
    },
    handleCancel() {
      this.visible = false;
      this.$refs["ruleForm"].resetFields();
    },
    init(id) {
      this.visible = true;
      this.id = id;
      if (this.id !== 0) {
        // 查询
        getSingleTopDomain(id)
          .then((res) => {
            var data = res.data;
            if (data) {
              this.form.name = data.name;
              this.form.src = data.src;
            } else {
              this.$message.error("未查询到数据!");
            }
          })
          .catch((err) => {
            this.$message.error(err.toString());
          });
      }
    },
  },
  mounted() {
    getSrcList()
      .then((res) => {
        this.srcs = res.data;
      })
      .catch((err) => {
        this.$message.error(err);
      });
  },
};
</script>
