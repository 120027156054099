<template>
  <a-card :bordered="false" class="content" style="width: 100%">
    <a-button type="primary" icon="plus" @click="add(0)">添加域名</a-button>
    <a-button type="primary" icon="search" @click="search" style="margin-left: 10px;">whois查询</a-button>
    <a-input-search
      placeholder="全局搜索"
      style="width: 300px; float: right"
      v-model="globalSearch"
      @search="onSearch"
    />
    <a-table
      ref="table"
      style="width: 100%; margin-top: 10px;"
      size="small"
      :loading="loading"
      :rowKey="(row) => row.id"
      :columns="columns"
      :data-source="data"
      showPagination="auto"
      :pagination="pagination"
      @change="handleTableChange"
      bordered
    >
      <div slot="filterDropdown" slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }" style="padding: 8px;">
        <a-input v-ant-ref="c => (searchInput = c)" :placeholder="`搜索 ${column.title}`" :value="selectedKeys[0]" @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])" @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"></a-input>
        <a-button type="primary" icon="search" size="small" style="width: 90px; margin-right: 8px" @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)">搜索</a-button>
        <a-button size="small" style="width: 90px" @click="handleReset(clearFilters)"> 重置 </a-button>
      </div>
      <a-icon slot="filterIcon" slot-scope="filtered" type="search" :style="{ color: filtered ? '#108ee9': undefined}"></a-icon>
      <span slot="registrar" slot-scope="text">
        <span v-if="(text instanceof Array)">
          <span v-for="res in text" :key="res">{{ res }}<br /></span>
        </span>
        <span v-else>
          {{ text }}
        </span>
      </span>
      <span slot="name_servers" slot-scope="text">
        <span v-if="(text instanceof Array)">
          <span v-for="res in text" :key="res">{{ res }}<br /></span>
        </span>
        <span v-else>
          {{ text }}
        </span>
      </span>
      <span slot="emails" slot-scope="text">
        <span v-if="(text instanceof Array)">
          <span v-for="res in text" :key="res">{{ res }}<br /></span>
        </span>
        <span v-else>
          {{ text }}
        </span>
      </span>
      <span slot="creation_date" slot-scope="text">
        <span v-if="(text instanceof Array)">
          <span v-for="res in text" :key="res">{{ res }}<br /></span>
        </span>
        <span v-else>
          {{ text }}
        </span>
      </span>
      <span slot="expiration_date" slot-scope="text">
        <span v-if="(text instanceof Array)">
          <span v-for="res in text" :key="res">{{ res }}<br /></span>
        </span>
        <span v-else>
          {{ text }}
        </span>
      </span>
      <span slot="action" slot-scope="text">
        <a-button
          type="primary"
          size="small"
          style="margin-right: 8px"
          @click="add(text.id)"
        >编辑</a-button>
        <a-button
          type="danger"
          size="small"
          @click="deleteDomain(text.id)"
        >删除</a-button>
      </span>
      <p slot="expandedRowRender" slot-scope="text" style="margin: 0">
        <pre>{{ JSON.stringify(text.whois_info, null, 4) }}</pre>
      </p>
    </a-table>
    <top-domain-add ref="topDomainAdd" />
    <domain-search ref="domainSearch" />
  </a-card>
</template>

<script>
import { getTopDomain, deleteTopDomain } from "@/api/information";
import TopDomainAdd from "./modules/TopDomainAdd";
import { Modal } from "ant-design-vue";
import DomainSearch from './modules/DomainSearch';
export default {
  components: {
    TopDomainAdd,
    DomainSearch,
  },
  data() {
    return {
      globalSearch: "",
      loading: false,
      data: [],
      columns: [
        {
          title: "编号",
          dataIndex: "id",
          ellipsis: true,
          scopedSlots: { customRender: "id" },
          width: 80,
          sorter: true
        },
        {
          title: "公司",
          dataIndex: "src.name",
          ellipsis: true,
          scopedSlots: {
            filterIcon: "filterIcon",
            filterDropdown: "filterDropdown"
          },
          onFilter: (value, record) => 
            (record.src.name ? record.src.name : "")
              .toString()
              .trim()
              .toLowerCase()
              .includes(value.trim().toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
          width: 80,
        },
        {
          title: "域名",
          dataIndex: "name",
          ellipsis: true,
          scopedSlots: {
            filterIcon: "filterIcon",
            filterDropdown: "filterDropdown"
          },
          // onFilter: (value, record) => 
            // (record.name ? record.name : "")
            //   .toString()
            //   .trim()
            //   .toLowerCase()
            //   .includes(value.trim().toLowerCase()),
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
          width: 80,
          sorter: true,
        },
        {
          title: "域名注册商",
          dataIndex: "registrar",
          ellipsis: true,
          scopedSlots: { customRender: "registrar" },
          width: 100,
        },
        {
          title: "NS服务器",
          dataIndex: "name_servers",
          ellipsis: true,
          scopedSlots: { customRender: "name_servers" },
          width: 80,
        },
        {
          title: "邮箱",
          dataIndex: "emails",
          ellipsis: true,
          scopedSlots: { customRender: "emails" },
          width: 80,
        },
        {
          title: "操作",
          ellipsis: true,
          width: 80,
          scopedSlots: { customRender: "action" },
        },
      ],
      pagination: {
        defaultPageSize: 10,
        showQuickJumper: true,
        showTotal: (total) => `共${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ["10", "50", "100", "200"],
        current: 1,
        pageSize: 10,
        onShowSizeChange: (current, pageSize) => (this.pagination.pageSize = pageSize),
      },
    };
  },
  methods: {
    onSearch () {
      this.fetch();
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      // 过滤
      let filterDic = {};
      for (var i in filters) {
        filterDic[i] = filters[i][0];
      }
      // 排序
      let orderingDic = {};
      if (sorter.order) {
        orderingDic["ordering"] =
          `${sorter.order === "ascend" ? "" : "-"}` + sorter.field;
      }
      this.fetch({...filterDic, ...orderingDic});
    },
    handleSearch (selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },
    handleReset (clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    add (id = 0) {
      this.$refs["topDomainAdd"].init(id);
    },
    search () {
      this.$refs["domainSearch"].visible = true;
    },
    fetch (query = {}) {
      this.loading = true;
      if (this.globalSearch) {
        query["search"] = this.globalSearch;
      }
      query["page_size"] = this.pagination.pageSize;
      query["page"] = this.pagination.current;
      getTopDomain(query)
        .then((res) => {
          const pagination = { ...this.pagination };
          pagination.total = parseInt(res.data.count);
          this.loading = false;
          this.data = res.data.results;
          this.pagination = pagination;
        })
        .catch((err) => {
          this.$message.error(err);
          this.loading = false;
        });
    },
    deleteDomain(id) {
      Modal.confirm({
        title: "警告",
        content: "您确定要删除此域名吗?",
        onOk: () => {
          deleteTopDomain(id)
            .then((res) => {
              if (res.status === 204) {
                this.$message.success("删除成功!");
                this.fetch();
              } else {
                this.$message.error("删除失败!");
              }
            })
            .catch((err) => {
              this.$message.error(err.toString());
            });
        },
        onCancel() {},
      });
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>
