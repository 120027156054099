import axios from "@/libs/api.request";

export const getSrcList = () => {
  return axios.request({
    url: "/info/src/get_all_src/",
    method: "get",
  });
};

export const getSrc = (query) => {
  return axios.request({
    url: "/info/src/",
    method: "get",
    params: query,
  });
};

export const changeSrc = (src_id, reqdata) => {
  return axios.request({
    url: "/info/src/" + src_id + "/",
    method: "put",
    data: reqdata,
  });
};

export const addSrc = (reqdata) => {
  return axios.request({
    url: "/info/src/",
    method: "post",
    data: reqdata,
  });
};

export const deleteSrc = (id) => {
  return axios.request({
    url: "/info/src/" + id + "/",
    method: "delete",
  });
};

export const getTopDomain = (query) => {
  return axios.request({
    url: "/info/top_domain/",
    method: "get",
    params: query,
  });
};

export const getSingleTopDomain = (id) => {
  return axios.request({
    url: "/info/top_domain/" + id + "/",
    method: "get",
  });
};

export const addTopDomain = (reqdata) => {
  return axios.request({
    url: "/info/top_domain/",
    method: "post",
    data: reqdata,
  });
};

export const deleteTopDomain = (id) => {
  return axios.request({
    url: "/info/top_domain/" + id + "/",
    method: "delete",
  });
};

export const changeTopDomain = (id, reqData) => {
  return axios.request({
    url: "/info/top_domain/" + id + "/",
    method: "put",
    data: reqData,
  });
};

export const getWhoIsInfo = (query) => {
  return axios.request({
    url: "/info/top_domain/get_whois_info/",
    method: "get",
    params: query,
  });
};

export const getSubDomain = (query) => {
  return axios.request({
    url: "/info/sub_domain/",
    method: "get",
    params: query,
  });
};

export const addSubDomain = (reqdata) => {
  return axios.request({
    url: "/info/sub_domain/",
    method: "post",
    data: reqdata,
  });
};

export const deleteSubDomain = (id) => {
  return axios.request({
    url: "/info/sub_domain/" + id + "/",
    method: "delete",
  });
};

export const scanSubDomain = (query) => {
  return axios.request({
    url: "/info/sub_domain/scan_sub_domain/",
    method: "get",
    params: query,
  });
};
